import axios from "axios";
import TokenService from "./TokenService";

const Repository = {
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },
  setHeaders() {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + TokenService.getToken();
  },
  removeHeaders() {
    axios.defaults.headers.common = {};
  },
  get(resource) {
    return axios.get(resource);
  },
  post(resource, data) {
    return axios.post(resource, data);
  },
  put(resource, data) {
    return axios.put(resource, data);
  },
  delete(resource) {
    return axios.delete(resource);
  },
  patch(resource, data) {
    return axios.patch(resource, data);
  },
  postFile(resource, file) {
    const formData = new FormData();
    const headers = { "Content-Type": "multipart/form-data" };
    formData.append("attachment", file);
    return axios.post(resource, formData, { headers });
  },
};

export default Repository;
