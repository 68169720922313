import Repository from "./Repository";

const resource = "user";

export default {
  register(data) {
    return Repository.post(`${resource}`, data);
  },
  sendRecoverPasswordMail(data) {
    return Repository.post(`${resource}/email`, data);
  },
  changePassword(userId, data) {
    return Repository.put(`${resource}/${userId}`, data);
  },
  changeTemporalPassword(email, data) {
    return Repository.put(`${resource}/${email}/temporal`, data);
  },
  updateData(userId, data) {
    return Repository.put(`${resource}/${userId}/user`, data);
  },
};
