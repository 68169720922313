const STORAGE_KEY_TOKEN = "userToken";

export default {
  getToken() {
    return localStorage.getItem(STORAGE_KEY_TOKEN);
  },
  getUser() {
    return localStorage.getItem("user");
  },
  setToken(token) {
    localStorage.setItem(STORAGE_KEY_TOKEN, token);
  },
  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  },
};
