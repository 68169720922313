import Repository from "./Repository";

const resource = "surveys";

export default {
  getSurveys() {
    return Repository.get(`${resource}`);
  },
  getSurveysCount() {
    return Repository.get(`${resource}/count`);
  },
};
