import Repository from "./Repository";

const resource = "evaluation";

export default {
  saveSurvey(data) {
    return Repository.post(`${resource}`, data);
  },
  getSurveys(page, limit, surveyCode, after, before, patientDocument) {
    return Repository.get(
      `${resource}?order=1&page=${page}&limit=${limit}&surveyCode=${surveyCode}${
        after ? `&after=${after}` : ""
      }${before ? `&before=${before}` : ""}${
        patientDocument ? `&document=${patientDocument}` : ""
      }`
    );
  },
  getSurveyById(surveyId) {
    return Repository.get(`${resource}/${surveyId}`);
  },
  getCountByDoctor(doctorId) {
    return Repository.get(`${resource}/count-by-user/${doctorId}`);
  },
  getMostCompletedEvaluations() {
    return Repository.get(`${resource}/most-completed`);
  },
  getGroupedByMonth() {
    return Repository.get(`${resource}/by-month`);
  },
  getByPatientId(page, limit, patientDocument) {
    return Repository.get(
      `${resource}/patient-history?page=${page}&limit=${limit}${
        patientDocument ? `&patientId=${patientDocument}` : ""
      }`
    );
  },
};
