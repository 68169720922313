import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Buefy from "buefy";
import Repository from "./services/Repository";
import mixins from "./global/mixins";
import RepositoryFactory from "./services/RepositoryFactory";
import VueSession from "vue-session";
import Vuelidate from "vuelidate";
import store from "./store/index";
import filters from "../src/global/filters";
import VueApexCharts from "vue-apexcharts";
import { VueMaskDirective } from "v-mask";
import { abilitiesPlugin } from "@casl/vue";
import { CLICK_OUTSIDE } from "./global/directives";
import ability from "./config/ability";
import "buefy/dist/buefy.css";
import "../public/style.css";

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(VueSession);
Vue.use(Vuelidate);
Vue.use(VueApexCharts);

Vue.use(abilitiesPlugin, ability);

Vue.component("apexchart", VueApexCharts);
Vue.directive("mask", VueMaskDirective);
Vue.directive("click-outside", CLICK_OUTSIDE);
Vue.mixin(mixins);
Vue.prototype.$global = new Vue();
Repository.init(process.env.VUE_APP_BASE_URL);

new Vue({
  components: {
    RepositoryFactory,
    filters,
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
