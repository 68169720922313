const state = () => ({
  userToken: null,
});

const getters = {};

const actions = {
  saveUserToken({ commit }, payload) {
    commit("setUserToken", payload);
  },
};

const mutations = {
  setUserToken(state, payload) {
    state.userToken = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
