const state = () => ({
  userId: null,
  userData: null,
});

const getters = {};

const actions = {
  setUserId({ commit }, userId) {
    commit("setUserId", userId);
  },
  setUserData({ commit }, data) {
    commit("setUserData", data);
  },
};

const mutations = {
  setUserId(state, userId) {
    state.userId = userId;
  },
  setUserData(state, data) {
    state.userData = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
