<template>
  <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
  </b-loading>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
