import axios from "axios";
import store from "../store";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import router from "../router/index";

const SecurityInstance = axios.create({
  baseURL: `${process.env.VUE_APP_SECURITY_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

SecurityInstance.interceptors.request.use(
  async (request) => {
    const token = store.state.token.userToken;
    if (!token) return request;

    const user = jwt_decode(token);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) {
      request.headers.common.Authorization = `Bearer ${token}`;
      return request;
    }
    await axios
      .post(
        `${process.env.VUE_APP_SECURITY_BASE_URL}auth/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const { access_token } = response.data;
        if (access_token) store.dispatch("token/saveUserToken", access_token);
        request.headers.common.Authorization = `Bearer ${access_token}`;
      })
      .catch(() => {
        const token = store.state.token.userToken;
        delete request.headers.common["Authorization"];
        if (token) {
          localStorage.clear();
          router.push({ name: "login" });
        }
      });
    return request;
  },
  (error) => Promise.reject(error)
);

SecurityInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => Promise.reject(error || "Algo salió mal")
);

export default SecurityInstance;
