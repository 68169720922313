<template>
  <div class="footer-content py-1 pl-2">
    © Helpharma S.A 2021 Copyright Todos los derechos reservados
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer-content {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 1.5rem;
  background-color: var(--dark-gray-color);
  font-size: 10px;
  color: var(--white-color);
  z-index: 4;
}
</style>
