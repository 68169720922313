export default {
  methods: {
    showToast(message, variant, time) {
      this.$buefy.toast.open({
        duration: time,
        message: message,
        position: "is-bottom",
        type: variant,
      });
    },
    showUndoDialog(message) {
      this.$buefy.snackbar.open({
        duration: 7000,
        message: message,
        type: "is-danger",
        position: "is-bottom-left",
        actionText: "Deshacer",
        cancelText: "Aceptar",
        onAction: () => {
          this.$global.$emit("restore");
        },
      });
    },
    changeLoaderStatus(status) {
      this.$global.$emit("showLoader", status);
    },
    clearTemporalSurveys() {
      this.$session.remove("temporalSurvey");
    },
    scrollTop() {
      this.$global.$emit("scrollFinish");
    },
    async getSurveyData() {
      this.changeLoaderStatus(true);
      try {
        const response = await this.$surveyService.getSurveyById(
          this.$route.params.surveyId
        );

        this.changeLoaderStatus(false);
        return response.data;
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
  },
};
