<template>
  <div id="app">
    <layout>
      <router-view />
    </layout>
    <Loader :isLoading="loading" />
    <patient-tab
      v-click-outside="closeTab"
      :patientData="patient"
    ></patient-tab>
    <Footer></Footer>
    <b-modal
      v-model="registerNewPatientModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal
      :width="600"
      :on-cancel="clearForm"
    >
      <template>
        <div>
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Nuevo paciente</p>
              <button type="button" class="delete" @click="closeForm" />
            </header>
            <section class="modal-card-body">
              <b-field
                label="Tipo documento"
                :message="[
                  {
                    'El tipo de documento es obligatorio.':
                      !$v.documentTypeId.required && $v.documentTypeId.$error,
                  },
                ]"
                :type="$v.documentTypeId.$error ? 'is-danger' : 'is-dark'"
              >
                <b-select
                  placeholder="Seleccione"
                  v-model="documentTypeId"
                  rounded
                  expanded
                  @input="$v.documentTypeId.$touch()"
                  @blur="$v.documentTypeId.$touch()"
                >
                  <option value>Seleccione</option>
                  <option
                    v-for="document in documentTypes"
                    :key="document.id"
                    :value="document.id"
                    >{{ document.name }}</option
                  >
                </b-select>
              </b-field>
              <b-field label="Número de doumento" type="is-dark">
                <b-input
                  v-model="newDoument"
                  rounded
                  placeholder="Número de ocumento"
                  required
                >
                </b-input>
              </b-field>

              <b-field
                label="Nombre completo"
                :message="[
                  {
                    'El nombre es obligatorio.':
                      !$v.patientName.required && $v.patientName.$error,
                  },
                  {
                    'La cantidad máxima de caracteres son 150.': !$v.patientName
                      .maxLength,
                  },
                  {
                    'El nombre no debe tener caracteres especiales ni números.': !$v
                      .patientName.nameRegex,
                  },
                ]"
                :type="$v.patientName.$error ? 'is-danger' : 'is-dark'"
              >
                <b-input
                  type="text"
                  placeholder="Nombre"
                  v-model="patientName"
                  rounded
                  @input="$v.patientName.$touch()"
                  @blur="$v.patientName.$touch()"
                >
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <b-button label="Close" class="clear-button" />
              <b-button label="Crear" class="save-button" @click="validate" />
            </footer>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  helpers,
  numeric,
} from "vuelidate/lib/validators";

import Layout from "./components/root/Layout";
import Loader from "./components/root/Loader";
import PatientTab from "./components/root/PatientTab.vue";
import Footer from "./components/root/Footer.vue";

const nameRegex = helpers.regex("alpha", /^[0-9a-zA-ZÀ-ÿ\u00f1\u00d1., ]*$/);
export default {
  name: "App",
  components: {
    Layout,
    Loader,
    PatientTab,
    Footer,
  },
  data() {
    return {
      loading: false,
      registerNewPatientModal: false,
      newDoument: "",
      patientName: "",
      documentTypeId: null,
      documentTypes: [],
    };
  },
  async created() {
    this.$global.$on("showLoader", (status) => {
      this.loading = status;
    });

    this.$global.$on("scrollFinish", () => {
      window.scrollTo(0, 0);
    });

    this.$global.$on("patientSearch", (document) => {
      this.getPatient(document);
    });

    await this.getDocumentTypes();
  },
  computed: {
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    async getPatient(document) {
      try {
        this.changeLoaderStatus(true);
        const response = await this.$patientService.findPatientByDocument(
          document
        );
        if (response.data) {
          this.$store.dispatch("patient/setPatientData", {
            name: response.data.name,
            //email: "paciente@correo.com",
            document: document,
          });
          this.$global.$emit("patientLoaded");
        } else {
          this.$buefy.dialog.confirm({
            message: `No ha sido encontrado el paciente con identificación ${document} ¿desea registrarlo?.`,
            confirmText: "Aceptar",
            cancelText: "Cancelar",
            type: "is-info",
            onConfirm: () => {
              this.registerNewPatientModal = true;
              this.newDoument = document;
            },
            onCancel: () => {
              this.$global.$emit("registerCancel");
            },
          });
        }
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    async getDocumentTypes() {
      try {
        this.changeLoaderStatus(true);

        const response = await this.$patientService.getDocumentTypes();

        this.documentTypes = response.data;
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    async savePatient() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          name: this.patientName,
          document: this.newDoument,
          document_type_id: parseInt(this.documentTypeId),
        };
        const response = await this.$patientService.savePatient(data);

        this.closeForm();
        this.getPatient(response.data.document);
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        this.changeLoaderStatus(false);
      }
    },
    closeTab() {
      this.$global.$emit("closeTab");
    },
    clearForm() {
      this.patientName = "";
      this.newDoument = "";
      this.documentTypeId = "";
    },
    closeForm() {
      this.clearForm();
      this.registerNewPatientModal = false;
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.savePatient();
      }
    },
  },
  validations: {
    patientName: {
      required,
      nameRegex,
      maxLength: maxLength(150),
    },
    newDoument: {
      required,
      numeric,
      minLength: minLength(3),
      maxLength: maxLength(12),
    },
    documentTypeId: {
      required,
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
