import { AbilityBuilder } from "@casl/ability";
import ability from "./ability";
import store from "../store";

const getPermission = () => {
  const permissions = store.state.permissions.permissions || [];
  return permissions;
};

export default function defineAbilityFor() {
  const { can, rules } = new AbilityBuilder();
  const permissions = getPermission();
  if (permissions.length) {
    permissions.forEach((permission) => {
      can(permission);
    });
  }
  return ability.update(rules);
}
