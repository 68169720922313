export const permissions = {
  SEE_SURVEYS_PASI: "CLINIMETRIAS.VISUALIZAR_PASI",
  SEE_SURVEYS_DLQI: "CLINIMETRIAS.VISUALIZAR_DLQI",
  SEE_SURVEYS_BSA: "CLINIMETRIAS.VISUALIZAR_BSA",
  SEE_SURVEYS_NAPSI: "CLINIMETRIAS.VISUALIZAR_NAPSI",
  SEE_SURVEYS_NAPSIMM: "CLINIMETRIAS.VISUALIZAR_NAPSIMM",
  SEE_SURVEYS_PGA: "CLINIMETRIAS.VISUALIZAR_PGA",
  SEE_SURVEYS_TOPAS: "CLINIMETRIAS.VISUALIZAR_TOPAS",
  SEE_SURVEYS_EASI: "CLINIMETRIAS.VISUALIZAR_EASI",
  SEE_SURVEYS_POEM: "CLINIMETRIAS.VISUALIZAR_POEM",
  SEE_SURVEYS_SCORAD: "CLINIMETRIAS.VISUALIZAR_SCORAD",
  SEE_SURVEYS_HURLEY: "CLINIMETRIAS.VISUALIZAR_HURLEY",
  SEE_SURVEYS_HIS4: "CLINIMETRIAS.VISUALIZAR_HIS4",
  SEE_SURVEYS_UAS7: "CLINIMETRIAS.VISUALIZAR_UAS7",
  SEE_SURVEYS_UCT: "CLINIMETRIAS.VISUALIZAR_UCT",
  SEE_SURVEYS_WHOQOL_BREF: "CLINIMETRIAS.VISUALIZAR_WHOQOL_BREF",
  SEE_SURVEYS_ASSOCIATED_RISK: "CLINIMETRIAS.VISUALIZAR_ASSOCIATED_RISK",
  SEE_SURVEYS_NRS: "CLINIMETRIAS.VISUALIZAR_NRS",
  SEE_SURVEYS_ADCT: "CLINIMETRIAS.VISUALIZAR_ADCT",
  SEE_SURVEYS_IDQOL: "CLINIMETRIAS.VISUALIZAR_IDQOL",
  SEE_SURVEYS_CDLQI: "CLINIMETRIAS.VISUALIZAR_CDLQI",
  // Permisos para ver detalles
  SEE_DETAIL_SURVEY_PASI: "CLINIMETRIAS.VER_DETALLE_PASI",
  SEE_DETAIL_SURVEY_DLQI: "CLINIMETRIAS.VER_DETALLE_DLQI",
  SEE_DETAIL_SURVEY_BSA: "CLINIMETRIAS.VER_DETALLE_BSA",
  SEE_DETAIL_SURVEY_NAPSI: "CLINIMETRIAS.VER_DETALLE_NAPSI",
  SEE_DETAIL_SURVEY_NAPSIMM: "CLINIMETRIAS.VER_DETALLE_NAPSIMM",
  SEE_DETAIL_SURVEY_PGA: "CLINIMETRIAS.VER_DETALLE_PGA",
  SEE_DETAIL_SURVEY_TOPAS: "CLINIMETRIAS.VER_DETALLE_TOPAS",
  SEE_DETAIL_SURVEY_EASI: "CLINIMETRIAS.VER_DETALLE_EASI",
  SEE_DETAIL_SURVEY_POEM: "CLINIMETRIAS.VER_DETALLE_POEM",
  SEE_DETAIL_SURVEY_SCORAD: "CLINIMETRIAS.VER_DETALLE_SCORAD",
  SEE_DETAIL_SURVEY_HURLEY: "CLINIMETRIAS.VER_DETALLE_HURLEY",
  SEE_DETAIL_SURVEY_HIS4: "CLINIMETRIAS.VER_DETALLE_HIS4",
  SEE_DETAIL_SURVEY_UAS7: "CLINIMETRIAS.VER_DETALLE_UAS7",
  SEE_DETAIL_SURVEY_UCT: "CLINIMETRIAS.VER_DETALLE_UCT",
  SEE_DETAIL_SURVEY_WHOQOL_BREF: "CLINIMETRIAS.VER_DETALLE_WHOQOL_BREF",
  SEE_DETAIL_SURVEY_ASSOCIATED_RISK: "CLINIMETRIAS.VER_DETALLE_WHOQOL_BREF",
  SEE_DETAIL_SURVEY_NRS: "CLINIMETRIAS.VER_DETALLE_NRS",
  SEE_DETAIL_SURVEY_ADCT: "CLINIMETRIAS.VER_DETALLE_ADCT",
  SEE_DETAIL_SURVEY_IDQOL: "CLINIMETRIAS.VER_DETALLE_IDQOL",
  SEE_DETAIL_SURVEY_CDLQI: "CLINIMETRIAS.VER_DETALLE_CDLQI",
  // Permisos para crear encuestas
  CREATE_SURVEY_PASI: "CLINIMETRIAS.CREAR_PASI",
  CREATE_SURVEY_DLQI: "CLINIMETRIAS.CREAR_DLQI",
  CREATE_SURVEY_BSA: "CLINIMETRIAS.CREAR_BSA",
  CREATE_SURVEY_NAPSI: "CLINIMETRIAS.CREAR_NAPSI",
  CREATE_SURVEY_NAPSIMM: "CLINIMETRIAS.CREAR_NAPSIMM",
  CREATE_SURVEY_PGA: "CLINIMETRIAS.CREAR_PGA",
  CREATE_SURVEY_TOPAS: "CLINIMETRIAS.CREAR_TOPAS",
  CREATE_SURVEY_EASI: "CLINIMETRIAS.CREAR_EASI",
  CREATE_SURVEY_POEM: "CLINIMETRIAS.CREAR_POEM",
  CREATE_SURVEY_SCORAD: "CLINIMETRIAS.CREAR_SCORAD",
  CREATE_SURVEY_HURLEY: "CLINIMETRIAS.CREAR_HURLEY",
  CREATE_SURVEY_HIS4: "CLINIMETRIAS.CREAR_HIS4",
  CREATE_SURVEY_UAS7: "CLINIMETRIAS.CREAR_UAS7",
  CREATE_SURVEY_UCT: "CLINIMETRIAS.CREAR_UCT",
  CREATE_SURVEY_WHOQOL_BREF: "CLINIMETRIAS.CREAR_WHOQOL_BREF",
  CREATE_SURVEY_ASSOCIATED_RISK: "CLINIMETRIAS.CREAR_ASSOCIATED_RISK",
  CREATE_SURVEY_NRS: "CLINIMETRIAS.CREAR_NRS",
  CREATE_SURVEY_ADCT: "CLINIMETRIAS.CREAR_ADCT",
  CREATE_SURVEY_IDQOL: "CLINIMETRIAS.CREAR_IDQOL",
  CREATE_SURVEY_CDLQI: "CLINIMETRIAS.CREAR_CDLQI",
  //Permisos para gestion de pacientes
  SEE_PATIENTS: "CLINIMETRIAS.VER_PACIENTES",
  CREATE_PATIENT: "CLINIMETRIAS.CREAR_PACIENTE",
  EDIT_PATIENT: "CLINIMETRIAS.EDITAR_PACIENTE",
  VIEW_PATIENT_HISTORY: "CLINIMETRIAS.VER_HISTORIAL_DE_PACIENTE",
  CHANGE_STATUS_OF_PATIENT: "CLINIMETRIAS.CAMBIAR_ESTADO_DE_PACIENTE",
  //Reportes
  SEE_SIMPLE_REPORT: "CLINIMETRIAS.VER_REPORTE_SIMPLE",
  SEE_DETAILED_REPORT: "CLINIMETRIAS.VER_REPORTE_DETALLADO",
  DOWNLOAD_SIMPLE_REPORT: "CLINIMETRIAS.DESCARGAR_REPORTE_SIMPLE",
  DOWNLOAD_DETAILED_REPORT: "CLINIMETRIAS.DESCARGAR_REPORTE_DETALLADO",
};
