import Vue from "vue";
import VueRouter from "vue-router";
import ability from "../config/ability";
import defineAbilityFor from "../config/rules";
import { permissions } from "../enum/applicationPermissions";
import store from "../store";

defineAbilityFor();
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../components/root/Dashboard.vue"),
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("../views/MainContent.vue"),
      },
      {
        path: "/encuestas/act",
        component: () => import("../views/surveys/ACT/Content.vue"),
        children: [
          {
            path: "",
            name: "ActList",
            // meta: { authorize: "" },
            component: () => import("../views/surveys/ACT/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-act",
            // meta: { authorize: "" },
            component: () => import("../views/surveys/ACT/ACT.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-act",
            // meta: { authorize: "" },
            component: () => import("../views/surveys/ACT/ACT.vue"),
          },
        ],
      },
      {
        path: "/encuestas/poem",
        component: () => import("../views/surveys/POEM/Content.vue"),
        children: [
          {
            path: "",
            name: "PoemList",
            meta: { authorize: [permissions.SEE_SURVEYS_POEM] },
            component: () => import("../views/surveys/POEM/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-poem",
            meta: {
              authorize: [permissions.CREATE_SURVEY_POEM],
            },
            component: () => import("../views/surveys/POEM/POEM.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-poem",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_POEM],
            },
            component: () => import("../views/surveys/POEM/POEM.vue"),
          },
        ],
      },
      {
        path: "/encuestas/whoql-bref",
        component: () => import("../views/surveys/WHOQOL_BREF/Content.vue"),
        children: [
          {
            path: "",
            name: "WhoqlList",
            meta: { authorize: [permissions.SEE_SURVEYS_WHOQOL_BREF] },
            component: () => import("../views/surveys/WHOQOL_BREF/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-whoql",
            meta: {
              authorize: [permissions.CREATE_SURVEY_WHOQOL_BREF],
            },
            component: () =>
              import("../views/surveys/WHOQOL_BREF/WHOQOL_BREF.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-whoql",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_WHOQOL_BREF],
            },
            component: () =>
              import("../views/surveys/WHOQOL_BREF/WHOQOL_BREF.vue"),
          },
        ],
      },
      {
        path: "/encuestas/uct",
        component: () => import("../views/surveys/UCT/Content.vue"),
        children: [
          {
            path: "",
            name: "UctList",
            meta: { authorize: [permissions.SEE_SURVEYS_UCT] },
            component: () => import("../views/surveys/UCT/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-uct",
            meta: {
              authorize: [permissions.CREATE_SURVEY_UCT],
            },
            component: () => import("../views/surveys/UCT/UCT.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-uct",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_UCT],
            },
            component: () => import("../views/surveys/UCT/UCT.vue"),
          },
        ],
      },
      {
        path: "/encuestas/pga",
        component: () => import("../views/surveys/PGA/Content.vue"),
        children: [
          {
            path: "",
            name: "PgaList",
            meta: { authorize: [permissions.SEE_SURVEYS_PGA] },
            component: () => import("../views/surveys/PGA/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-pga",
            meta: {
              authorize: [permissions.CREATE_SURVEY_PGA],
            },
            component: () => import("../views/surveys/PGA/PGA.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-pga",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_PGA],
            },
            component: () => import("../views/surveys/PGA/PGA.vue"),
          },
        ],
      },
      {
        path: "/encuestas/cat",
        component: () => import("../views/surveys/CAT/Content.vue"),
        children: [
          {
            path: "",
            name: "CatList",
            // meta: { authorize: "" },
            component: () => import("../views/surveys/CAT/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-cat",
            // meta: { authorize: "" },
            component: () => import("../views/surveys/CAT/CAT.vue"),
          },
          {
            path: "nuevo",
            name: "detail-cat",
            // meta: { authorize: "" },
            component: () => import("../views/surveys/CAT/CAT.vue"),
          },
        ],
      },
      {
        path: "/encuestas/his4",
        component: () => import("../views/surveys/HIS4/Content.vue"),
        children: [
          {
            path: "",
            name: "His4List",
            meta: { authorize: [permissions.SEE_SURVEYS_HIS4] },
            component: () => import("../views/surveys/HIS4/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-his4",
            meta: {
              authorize: [permissions.CREATE_SURVEY_HIS4],
            },
            component: () => import("../views/surveys/HIS4/HIS4.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-his4",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_HIS4],
            },
            component: () => import("../views/surveys/HIS4/HIS4.vue"),
          },
        ],
      },
      {
        path: "/encuestas/dlqi",
        component: () => import("../views/surveys/DLQI/Content.vue"),
        children: [
          {
            path: "",
            name: "DlqiList",
            meta: { authorize: [permissions.SEE_SURVEYS_DLQI] },
            component: () => import("../views/surveys/DLQI/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-dlqi",
            meta: {
              authorize: [permissions.CREATE_SURVEY_DLQI],
            },
            component: () => import("../views/surveys/DLQI/DLQI.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-dlqi",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_DLQI],
            },
            component: () => import("../views/surveys/DLQI/DLQI.vue"),
          },
        ],
      },
      {
        path: "/encuestas/cdlqi",
        component: () => import("../views/surveys/CDLQI/Content.vue"),
        children: [
          {
            path: "",
            name: "CdlqiList",
            meta: { authorize: [permissions.SEE_SURVEYS_CDLQI] },
            component: () => import("../views/surveys/CDLQI/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-cdlqi",
            meta: {
              authorize: [permissions.CREATE_SURVEY_CDLQI],
            },
            component: () => import("../views/surveys/CDLQI/CDLQI.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-cdlqi",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_CDLQI],
            },
            component: () => import("../views/surveys/CDLQI/CDLQI.vue"),
          },
        ],
      },
      {
        path: "/encuestas/idqol",
        component: () => import("../views/surveys/IDQOL/Content.vue"),
        children: [
          {
            path: "",
            name: "IdqolList",
            meta: { authorize: [permissions.SEE_SURVEYS_IDQOL] },
            component: () => import("../views/surveys/IDQOL/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-idqol",
            meta: {
              authorize: [permissions.CREATE_SURVEY_IDQOL],
            },
            component: () => import("../views/surveys/IDQOL/IDQOL.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-idqol",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_IDQOL],
            },
            component: () => import("../views/surveys/IDQOL/IDQOL.vue"),
          },
        ],
      },
      {
        path: "/encuestas/hurley",
        component: () => import("../views/surveys/HURLEY/Content.vue"),
        children: [
          {
            path: "",
            name: "HurleyList",
            meta: { authorize: [permissions.SEE_SURVEYS_HURLEY] },
            component: () => import("../views/surveys/HURLEY/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-hurley",
            meta: {
              authorize: [permissions.CREATE_SURVEY_HURLEY],
            },
            component: () => import("../views/surveys/HURLEY/HURLEY.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-hurley",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_HURLEY],
            },
            component: () => import("../views/surveys/HURLEY/HURLEY.vue"),
          },
        ],
      },
      {
        path: "/encuestas/pasi",
        component: () => import("../views/surveys/PASI/Content.vue"),
        children: [
          {
            path: "",
            name: "PasiList",
            meta: { authorize: [permissions.SEE_SURVEYS_PASI] },
            component: () => import("../views/surveys/PASI/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-pasi",
            meta: {
              authorize: [permissions.CREATE_SURVEY_PASI],
            },
            component: () => import("../views/surveys/PASI/PASI.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-pasi",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_PASI],
            },
            component: () => import("../views/surveys/PASI/PASI.vue"),
          },
        ],
      },
      {
        path: "/encuestas/bsa",
        component: () => import("../views/surveys/BSA/Content.vue"),
        children: [
          {
            path: "",
            name: "BsaList",
            meta: { authorize: [permissions.SEE_SURVEYS_BSA] },
            component: () => import("../views/surveys/BSA/List"),
          },
          {
            path: "nuevo",
            name: "new-bsa",
            meta: {
              authorize: [permissions.CREATE_SURVEY_BSA],
            },
            component: () => import("../views/surveys/BSA/BSA.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-bsa",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_BSA],
            },
            component: () => import("../views/surveys/BSA/BSA.vue"),
          },
        ],
      },
      {
        path: "/encuestas/easi",
        component: () => import("../views/surveys/EASI/Content.vue"),
        children: [
          {
            path: "",
            name: "EasiList",
            meta: { authorize: [permissions.SEE_SURVEYS_EASI] },
            component: () => import("../views/surveys/EASI/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-easi",
            meta: {
              authorize: [permissions.CREATE_SURVEY_EASI],
            },
            component: () => import("../views/surveys/EASI/EASI.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-easi",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_EASI],
            },
            component: () => import("../views/surveys/EASI/EASI.vue"),
          },
        ],
      },
      {
        path: "/encuestas/riesgo-asociado",
        component: () => import("../views/surveys/ASSOCIATEDRISK/Content.vue"),
        children: [
          {
            path: "",
            name: "Riesgo-asociadoList",
            meta: { authorize: [permissions.SEE_SURVEYS_ASSOCIATED_RISK] },
            component: () => import("../views/surveys/ASSOCIATEDRISK/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-riesgo-asociado",
            meta: {
              authorize: [permissions.CREATE_SURVEY_ASSOCIATED_RISK],
            },
            component: () =>
              import("../views/surveys/ASSOCIATEDRISK/ASSOCIATEDRISK.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-riesgo-asociado",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_ASSOCIATED_RISK],
            },
            component: () =>
              import("../views/surveys/ASSOCIATEDRISK/ASSOCIATEDRISK.vue"),
          },
        ],
      },
      {
        path: "/encuestas/uas7",
        component: () => import("../views/surveys/UAS7/Content.vue"),
        children: [
          {
            path: "",
            name: "Uas7List",
            meta: { authorize: [permissions.SEE_SURVEYS_UAS7] },
            component: () => import("../views/surveys/UAS7/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-uas7",
            meta: {
              authorize: [permissions.CREATE_SURVEY_UAS7],
            },
            component: () => import("../views/surveys/UAS7/UAS7.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-uas7",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_UAS7],
            },
            component: () => import("../views/surveys/UAS7/UAS7.vue"),
          },
        ],
      },
      {
        path: "/encuestas/napsi",
        component: () => import("../views/surveys/NAPSI/Content.vue"),
        children: [
          {
            path: "",
            name: "NapsiList",
            meta: { authorize: [permissions.SEE_SURVEYS_NAPSI] },
            component: () => import("../views/surveys/NAPSI/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-napsi",
            meta: {
              authorize: [permissions.CREATE_SURVEY_NAPSI],
            },
            component: () => import("../views/surveys/NAPSI/NAPSI.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-napsi",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_NAPSI],
            },
            component: () => import("../views/surveys/NAPSI/NAPSI.vue"),
          },
        ],
      },
      {
        path: "/encuestas/scorad",
        component: () => import("../views/surveys/SCORAD/Content.vue"),
        children: [
          {
            path: "",
            name: "ScoradList",
            meta: { authorize: [permissions.SEE_SURVEYS_SCORAD] },
            component: () => import("../views/surveys/SCORAD/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-scorad",
            meta: {
              authorize: [permissions.CREATE_SURVEY_SCORAD],
            },
            component: () => import("../views/surveys/SCORAD/SCORAD.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-scorad",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_SCORAD],
            },
            component: () => import("../views/surveys/SCORAD/SCORAD.vue"),
          },
        ],
      },
      {
        path: "/encuestas/topas",
        component: () => import("../views/surveys/TOPAS/Content.vue"),
        children: [
          {
            path: "",
            name: "TopasList",
            meta: { authorize: [permissions.SEE_SURVEYS_TOPAS] },
            component: () => import("../views/surveys/TOPAS/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-topas",
            meta: {
              authorize: [permissions.CREATE_SURVEY_TOPAS],
            },
            component: () => import("../views/surveys/TOPAS/TOPAS.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-topas",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_TOPAS],
            },
            component: () => import("../views/surveys/TOPAS/TOPAS.vue"),
          },
        ],
      },
      {
        path: "/encuestas/napsi-mm",
        component: () => import("../views/surveys/NAPSIMM/Content.vue"),
        children: [
          {
            path: "",
            name: "NapsimmList",
            meta: { authorize: [permissions.SEE_SURVEYS_NAPSIMM] },
            component: () => import("../views/surveys/NAPSIMM/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-napsimm",
            meta: {
              authorize: [permissions.CREATE_SURVEY_NAPSIMM],
            },
            component: () => import("../views/surveys/NAPSIMM/NAPSIMM.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-napsimm",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_NAPSIMM],
            },
            component: () => import("../views/surveys/NAPSIMM/NAPSIMM.vue"),
          },
        ],
      },
      {
        path: "/encuestas/adct",
        component: () => import("../views/surveys/POEM/Content.vue"),
        children: [
          {
            path: "",
            name: "AdctList",
            meta: { authorize: [permissions.SEE_SURVEYS_ADCT] },
            component: () => import("../views/surveys/ADCT/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-adct",
            meta: {
              authorize: [permissions.CREATE_SURVEY_ADCT],
            },
            component: () => import("../views/surveys/ADCT/ADCT.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-adct",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_ADCT],
            },
            component: () => import("../views/surveys/ADCT/ADCT.vue"),
          },
        ],
      },
      {
        path: "/encuestas/nrs",
        component: () => import("../views/surveys/POEM/Content.vue"),
        children: [
          {
            path: "",
            name: "NrsList",
            meta: { authorize: [permissions.SEE_SURVEYS_NRS] },
            component: () => import("../views/surveys/NRS/List.vue"),
          },
          {
            path: "nuevo",
            name: "new-nrs",
            meta: {
              authorize: [permissions.CREATE_SURVEY_NRS],
            },
            component: () => import("../views/surveys/NRS/NRS.vue"),
          },
          {
            path: ":surveyId",
            name: "detail-nrs",
            meta: {
              authorize: [permissions.SEE_DETAIL_SURVEY_NRS],
            },
            component: () => import("../views/surveys/NRS/NRS.vue"),
          },
        ],
      },
      {
        path: "user/",
        component: () => import("../views/user/Content.vue"),
        children: [
          {
            path: "change-password",
            name: "change-password",
            component: () => import("../views/user/ChangePassword.vue"),
          },
          {
            path: "update",
            name: "update",
            component: () => import("../views/user/UpdateData.vue"),
          },
        ],
      },
      {
        path: "/pacientes",
        component: () => import("../views/administration/patients/Content.vue"),
        children: [
          {
            path: "",
            name: "patients",
            meta: { authorize: [permissions.SEE_PATIENTS] },
            component: () =>
              import("../views/administration/patients/List.vue"),
          },
          {
            path: "crear-editar",
            name: "patients-create-edit",
            meta: {
              authorize: [permissions.CREATE_PATIENT, permissions.EDIT_PATIENT],
            },
            component: () =>
              import("../views/administration/patients/CreateEdit.vue"),
          },
        ],
      },
      {
        path: "/reportes",
        component: () => import("../views/administration/patients/Content.vue"),
        children: [
          {
            path: "",
            name: "report",
            meta: {
              authorize: [permissions.SEE_SIMPLE_REPORT],
            },
            component: () =>
              import("../views/administration/reports/simple/List.vue"),
          },
          {
            path: "detallado",
            name: "detailed-report",
            meta: {
              authorize: [permissions.SEE_DETAILED_REPORT],
            },
            component: () =>
              import("../views/administration/reports/detailed/List.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/Login.vue"),
    beforeEnter(to, form, next) {
      if (store.state.token.userToken) {
        next({ name: "Home" });
      } else {
        next();
      }
    },
  },
  {
    path: "/permission-denied",
    name: "permission-denied",
    component: () => import("../views/permission-denied/PermissionDenied.vue"),
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("../views/login/Register.vue"),
  // },
  {
    path: "/recover-password",
    name: "recover-password",
    component: () => import("../views/login/ForgotPassword.vue"),
  },
  {
    path: "/restore-password/:email",
    name: "restore-password",
    component: () => import("../views/user/RecoverPassword.vue"),
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register",
    "/recover-password",
    "/permission-denied",
  ];

  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.state.token.userToken;

  if (authRequired && !loggedIn) {
    next({ name: "login" });
  }

  // check if route is restricted by role
  const { authorize } = to.meta;
  if (
    loggedIn &&
    authorize &&
    !authorize.some((permission) => ability.can(permission))
  ) {
    // role not authorised so redirect to home page
    router.push({ name: "permission-denied" }).catch(() => {});
  }

  next();
});

export default router;
