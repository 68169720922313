const state = () => ({
  permissions: null,
});

const getters = {};

const actions = {
  setPermissions({ commit }, payload) {
    commit("setPermissions", payload);
  },
};

const mutations = {
  setPermissions(state, payload) {
    state.permissions = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
