import Repository from "./Repository";
import SecurityInstance from "../utils/securityInstanceAxios";

const resource = "auth";

export default {
  async loginSecurity(data) {
    return await SecurityInstance.post(`${resource}/login`, data);
  },
  async logoutSecurity() {
    return await SecurityInstance.post(`${resource}/logout`, {});
  },
  authenticate(data) {
    return Repository.post(`${resource}`, data);
  },
  recoverPassword(data) {
    return Repository.post(`${resource}/recover-password`, data);
  },
};
