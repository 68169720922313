<template>
  <div :class="patientData ? '' : 'main-tab'">
    <div class="stycky-user-tab" :class="expand == true ? 'expanded' : ''">
      <div class="tab-header">
        <div @click="expand = !expand">
          <b-icon
            :icon="expand == true ? 'close-circle-outline' : 'account'"
            class="ml-3 mt-3 interact-icon"
          ></b-icon>
        </div>
        <div class="tab-title pr-5" v-if="expand == true">
          <p>Paciente</p>
        </div>
      </div>
      <div class="user-data pl-5 mt-5" v-if="expand == true">
        <p class="user-attribute">
          <strong>Nombre:</strong>
          {{ patientData ? patientData.name : "" }}
        </p>
        <p class="user-attribute mt-5">
          <strong>Documento:</strong>
          {{ patientData ? patientData.document : "" }}
        </p>
        <!-- <p class="user-attribute mt-5">
          <strong>Correo:</strong>
          {{ patientData ? patientData.email : "" }}
        </p> -->
        <div class="delete-patient mt-5">
          <div class="delete-container" @click="deleteUserData">
            <b-icon icon="trash-can" class="mt-5 interact-icon"></b-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    patientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      expand: false,
    };
  },
  created() {
    this.$global.$on("patientLoaded", () => {
      this.expand = true;
    });

    this.$global.$on("closeTab", () => {
      this.expand = false;
    });
  },
  methods: {
    deleteUserData() {
      this.changeLoaderStatus(true);
      this.$store.dispatch("patient/setPatientData", null);
      setTimeout(() => {
        this.$global.$emit("patientDataDeleted");
        this.changeLoaderStatus(false);
      }, 1000);
    },
  },
};
</script>

<style scoped>
.main-tab {
  display: none;
}

.stycky-user-tab {
  width: 3rem;
  height: 3rem;
  position: fixed;
  top: 18%;
  right: 0;
  background-color: yellow;
  font-size: 20px;
  background-color: var(--medium-blue-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  transition: width 1s, height 1s;
  z-index: 2;
}

.stycky-user-tab:hover {
  opacity: 0.7;
}

.stycky-user-tab.expanded:hover {
  opacity: 1;
}

.stycky-user-tab.expanded {
  display: flow-root;
  width: 17rem;
  height: 17rem;
  cursor: inherit;
  transition: width 1s, height 1s, border-top-left-radius 3s,
    border-bottom-left-radius 3s;
}

.interact-icon {
  cursor: pointer;
}

.tab-title {
  font-size: 28px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.tab-header {
  width: 100%;
  display: flex;
}

.user-attribute {
  font-size: 15px;
}

.user-attribute > strong {
  color: #ffffff;
}

.delete-container {
  cursor: pointer;
  width: 2rem;
  margin-right: auto;
  margin-left: auto;
}

.delete-patient {
  width: 90%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .stycky-user-tab {
    top: 26%;
  }

  .stycky-user-tab.expanded {
    width: 100%;
    height: 18rem;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
</style>
