import Repository from "./Repository";

const resource = "report";

export default {
  getSurveys(page, limit, initialDate, finalDate, surveyCode, patientDocument) {
    return Repository.get(
      `${resource}/all-evaluations?page=${page}&limit=${limit}&surveyCode=${surveyCode}${
        initialDate ? `&initialDate=${initialDate}` : ""
      }${finalDate ? `&finalDate=${finalDate}` : ""}${
        patientDocument ? `&document=${patientDocument}` : ""
      }`
    );
  },
  simple(initialDate, finalDate, surveyCode) {
    return Repository.get(
      `${resource}/simple?initialDate=${initialDate}&finalDate=${finalDate}${
        surveyCode ? `&surveyCode=${surveyCode}` : ""
      }`
    );
  },
  detailed(initialDate, finalDate, surveyCode) {
    return Repository.get(
      `${resource}/detailed?initialDate=${initialDate}&finalDate=${finalDate}${
        surveyCode ? `&surveyCode=${surveyCode}` : ""
      }`
    );
  },
};
