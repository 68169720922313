import Vue from "vue";
import AuthRepository from "./AuthRepository";
import ClinimetriasRepository from "./ClinimetriasRepository";
import PatientRepository from "./PatientRepository";
import SurveyRepository from "./SurveyRepository";
import UserRepository from "./UserRepository";
import ReportRepository from "./ReportRepository";

Vue.prototype.$authService = AuthRepository;
Vue.prototype.$surveyService = SurveyRepository;
Vue.prototype.$userService = UserRepository;
Vue.prototype.$clinimetriasService = ClinimetriasRepository;
Vue.prototype.$patientService = PatientRepository;
Vue.prototype.$reportRepository = ReportRepository;
