import Repository from "./Repository";

const resource = "patient";

export default {
  findPatientByDocument(document) {
    return Repository.get(`${resource}/by-document/${document}`);
  },
  getDocumentTypes() {
    return Repository.get(`document-types`);
  },
  savePatient(patientData) {
    return Repository.post(`${resource}/save`, patientData);
  },
  updatePatient(patientId, patientData) {
    return Repository.put(`${resource}/update/${patientId}`, patientData);
  },
  changePatientStatus(patientId, patientData) {
    return Repository.put(
      `${resource}/change-status/${patientId}`,
      patientData
    );
  },
  getPatients(page, limit) {
    return Repository.get(`${resource}/all?page=${page}&limit=${limit}`);
  },
};
