var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('layout',[_c('router-view')],1),_c('Loader',{attrs:{"isLoading":_vm.loading}}),_c('patient-tab',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeTab),expression:"closeTab"}],attrs:{"patientData":_vm.patient}}),_c('Footer'),_c('b-modal',{attrs:{"has-modal-card":"","trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-modal":"","width":600,"on-cancel":_vm.clearForm},model:{value:(_vm.registerNewPatientModal),callback:function ($$v) {_vm.registerNewPatientModal=$$v},expression:"registerNewPatientModal"}},[[_c('div',[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Nuevo paciente")]),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":_vm.closeForm}})]),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"label":"Tipo documento","message":[
                {
                  'El tipo de documento es obligatorio.':
                    !_vm.$v.documentTypeId.required && _vm.$v.documentTypeId.$error,
                } ],"type":_vm.$v.documentTypeId.$error ? 'is-danger' : 'is-dark'}},[_c('b-select',{attrs:{"placeholder":"Seleccione","rounded":"","expanded":""},on:{"input":function($event){return _vm.$v.documentTypeId.$touch()},"blur":function($event){return _vm.$v.documentTypeId.$touch()}},model:{value:(_vm.documentTypeId),callback:function ($$v) {_vm.documentTypeId=$$v},expression:"documentTypeId"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione")]),_vm._l((_vm.documentTypes),function(document){return _c('option',{key:document.id,domProps:{"value":document.id}},[_vm._v(_vm._s(document.name))])})],2)],1),_c('b-field',{attrs:{"label":"Número de doumento","type":"is-dark"}},[_c('b-input',{attrs:{"rounded":"","placeholder":"Número de ocumento","required":""},model:{value:(_vm.newDoument),callback:function ($$v) {_vm.newDoument=$$v},expression:"newDoument"}})],1),_c('b-field',{attrs:{"label":"Nombre completo","message":[
                {
                  'El nombre es obligatorio.':
                    !_vm.$v.patientName.required && _vm.$v.patientName.$error,
                },
                {
                  'La cantidad máxima de caracteres son 150.': !_vm.$v.patientName
                    .maxLength,
                },
                {
                  'El nombre no debe tener caracteres especiales ni números.': !_vm.$v
                    .patientName.nameRegex,
                } ],"type":_vm.$v.patientName.$error ? 'is-danger' : 'is-dark'}},[_c('b-input',{attrs:{"type":"text","placeholder":"Nombre","rounded":""},on:{"input":function($event){return _vm.$v.patientName.$touch()},"blur":function($event){return _vm.$v.patientName.$touch()}},model:{value:(_vm.patientName),callback:function ($$v) {_vm.patientName=$$v},expression:"patientName"}})],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{staticClass:"clear-button",attrs:{"label":"Close"}}),_c('b-button',{staticClass:"save-button",attrs:{"label":"Crear"},on:{"click":_vm.validate}})],1)])])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }